
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "QuickPurchasePageTitle",
  components: {},
})
export default class QuickPurchasePageTitle extends Vue {
  @Prop({ default: "" })
  private title!: string;

  get isDevsecops() {
    return this.title === "DEVSECOPS";
  }
}
