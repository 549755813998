
import { Component, Inject, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { QuickPurchaseData } from "@/types/localtypes";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";

@Component({
  name: "QuickPurchaseInvalidOrExpiredToken",
  components: { QuickPurchasePageTitle },
})
export default class QuickPurchaseInvalidOrExpiredToken extends Vue {
  @Inject() readonly quickPurchaseBus!: Vue;
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;
  @Action("showAppLoadingMask", { namespace: "application" })
  showAppLoadingMask!: () => void;
  @Action("hideAppLoadingMask", { namespace: "application" })
  hideAppLoadingMask!: () => void;

  get hasServerName() {
    return !!this.quickPurchaseData.serverName;
  }

  mounted() {
    this.quickPurchaseBus.$emit("shouldDisplayHostedPlanCta", false);
  }

  async getQuickPurchaseLink() {
    try {
      this.showAppLoadingMask();
      if (!this.hasServerName) {
        throw new Error("Server is undefined");
      }

      await this.$jfUsers.sendQuickPurchaseLinkViaMail(this.quickPurchaseData.serverName);
      this.hideAppLoadingMask();
      this.$jfNotification.success({ text: this.$jfMessages.quick_purchase_link_send_by_mail_success });
    } catch (e) {
      this.$log.error(e);
      this.hideAppLoadingMask();
      let errorMessage = e.httpStatus === 500 ? this.$jfMessages.app_something_went_wrong : e.httpStatusText;
      this.$jfNotification.error({
        title: "Error",
        text: errorMessage || e,
      });
    }
  }
}
